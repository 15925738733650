import React from 'react';
import { useNavigate } from 'react-router-dom';
import ModalBasic from '../../components/ModalBasic';

const WarningLine = ({ text, onView }) => (
  <div className="w-full px-3 py-4 bg-white">
    <div className="flex justify-between items-center w-full py-3.5 px-3 border-b border-[#D0D5DD] last:border-none">
      <div className="flex gap-2 items-center">
        {/* Warning Icon  */}
        <svg
          width="20"
          height="19"
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99935 6.40332V9.73665M9.99935 13.07H10.0077M18.3327 9.73665C18.3327 14.339 14.6017 18.07 9.99935 18.07C5.39698 18.07 1.66602 14.339 1.66602 9.73665C1.66602 5.13428 5.39698 1.40332 9.99935 1.40332C14.6017 1.40332 18.3327 5.13428 18.3327 9.73665Z"
            stroke="#FF4B4B"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="text-sm font-medium text-[#101828]">{text}</p>
      </div>
      <button
        type="button"
        className="text-[#FF4B4B] text-base hover:underline"
        onClick={onView}
      >
        View
      </button>
    </div>
  </div>
);

const ErrorsModal = ({
  isOpen,
  setIsOpen,
  numberOfDuplicates,
  numberOfMatching,
  numberOfUndepositMatching,
}) => {
  const navigate = useNavigate();
  return (
    <ModalBasic
      title="Transaction Issues"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      smallModal
    >
      {numberOfDuplicates > 0 && (
        <WarningLine
          text={`${numberOfDuplicates} Possible Duplicate Transactions Found`}
          onView={() => {
            navigate('/suggestions/', {
              state: { activeTab: 'Duplicate' },
            });
          }}
        />
      )}
      {numberOfMatching > 0 && (
        <WarningLine
          text={`${numberOfMatching} Possible Matching Transactions Found`}
          onView={() => {
            navigate('/suggestions/', {
              state: { activeTab: 'Matching' },
            });
          }}
        />
      )}
      {numberOfUndepositMatching > 0 && (
        <WarningLine
          text={`${numberOfUndepositMatching} Possible Undeposited Transactions Found`}
          onView={() => {
            navigate('/suggestions/', {
              state: { activeTab: 'Undeposited Transfer' },
            });
          }}
        />
      )}
    </ModalBasic>
  );
};

export default ErrorsModal;
