import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useSelector } from 'react-redux';
import TextInputDash from '../../components/TextInputDash';
import useClickOutside from '../../utils/useClickOutside';
import Transition from '../../utils/Transition';

import DropdownFull from '../../components/DropdownFull';
import {
  createCustomer,
  createJournalEntries,
  createMerchant,
  updateBillTags,
  updateJournalEntry,
  updateJournalEntryTags,
} from '../../API/backend_helper';
import CreateJournalEntryTable from './CreateJournalEntryTable';
import DatePickerDash from '../../components/DatePickerDash';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import { VIEWER } from '../../utils/Utils';

const TABLE_ROW_SCHEMA = {
  description: { value: '', error: false },
  categoryAccountId: { value: null, error: false },
  vendorId: { value: null, error: false },
  customerId: { value: null, error: false },
  projectId: { value: null, error: false },
  productId: { value: null, error: false },
  tags: { value: [], error: false },
  credit: { value: '', error: false },
  debit: { value: '', error: false },
};

const JournalEntryForm = ({
  activeEntry,
  setFormOpen,
  setTitle,
  setVendors,
  vendors,
  tags,
  customers,
  setCustomers,
  currencies,
  teamCurrency,
  allAccounts,
  loading,
  onDeleteJournalEntry,
  projects,
  getData,
  setAddEditLoader,
  products,
}) => {
  const formikRef = useRef(null);
  const { team } = useSelector((state) => state.Team);

  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  const [isLineItemsError, setIsLineItemsError] = useState(false);
  const [dropdownCustomers, setDropdownCustomers] = useState([...customers]);

  useEffect(() => {
    setDropdownCustomers([...customers]);
  }, [customers]);

  // Create Entry Table
  const [entryRows, setEntryRows] = useState([
    JSON.parse(JSON.stringify(TABLE_ROW_SCHEMA)),
    JSON.parse(JSON.stringify(TABLE_ROW_SCHEMA)),
  ]);

  const checkEntryRowsError = () => {
    let isError = false;
    if (entryRows?.length === 0) {
      return true;
    }
    const tempRows = [...entryRows];
    entryRows.forEach((d, i) => {
      Object.keys(d || {})?.map((key) => {
        const tempRow = { ...entryRows[i] };
        if (
          !d[key].value &&
          key !== 'description' &&
          key !== 'credit' &&
          key !== 'debit' &&
          key !== 'customerId' &&
          key !== 'projectId' &&
          key !== 'productId' &&
          key !== 'vendorId' &&
          key !== 'tags'
        ) {
          tempRow[key].error = true;
          isError = true;
        }
        if (key === 'categoryAccountId' && !d[key].value) {
          tempRow[key].error = 'Account should not be empty';
          // isError = "Description should not be empty";
          isError = true;
        }
        if (key === 'description' && d[key].value?.trim()?.length === 0) {
          tempRow[key].error = 'Description should not be empty';
          // isError = "Description should not be empty";
          isError = true;
        }
        if (key === 'description' && d[key].value?.length > 300) {
          tempRow[key].error = 'Description must be 300 characters or less';
          isError = true;
        }

        if (key === 'credit' || key === 'debit') {
          if (d.credit.value && d.debit.value) {
            tempRow.credit.error = 'Either amount will be credit or debit.';
            tempRow.debit.error = 'Either amount will be credit or debit.';
            isError = true;
          }
        }

        if (key === 'credit' || key === 'debit') {
          if (!d.credit.value && !d.debit.value) {
            tempRow.credit.error = 'Credit or debit amount required.';
            tempRow.debit.error = 'Credit or debit amount required.';
            isError = true;
          }
        }
      });
    });
    setEntryRows(tempRows);
    return isError;
  };

  useEffect(() => {
    if (teamCurrency) {
      setSelectedCurrency(teamCurrency);
    }
  }, [teamCurrency]);

  useEffect(() => {
    if (activeEntry?.length > 0) {
      const customerTemp = [];
      const temp = [];
      activeEntry?.forEach((entry) => {
        const d = {
          description: { value: entry?.descriptionLine, error: false },
          categoryAccountId: { value: entry?.accountId, error: false },
        };
        if (entry?.vendorId) {
          d.vendorId = { value: entry?.vendorId, error: false };
        }
        if (entry?.projectId) {
          d.projectId = { value: entry?.projectId, error: false };
        } else {
          if (entry?.customerId) {
            if (!customerTemp?.find((cus) => cus?.id === entry?.customerId)) {
              customerTemp?.push(entry?.customer);
            }
            d.customerId = { value: entry?.customerId, error: false };
          }
        }
        if (entry?.productId) {
          d.productId = { value: entry?.productId, error: false };
        }
        if (entry?.tags?.length > 0) {
          const _tags = entry?.tags?.map((t) => t?.id);
          d.tags = { value: _tags, error: false };
        }
        if (entry?.amountDebit && !entry?.amountCredit) {
          d.debit = {
            value: parseFloat(entry?.amountDebit || 0)?.toFixed(2),
            error: false,
          };
          d.credit = { value: '', error: false };
        }
        if (entry?.amountCredit && !entry?.amountDebit) {
          d.credit = {
            value: parseFloat(entry?.amountCredit || 0)?.toFixed(2),
            error: false,
          };
          d.debit = { value: '', error: false };
        }
        temp?.push(d);
      });
      setDropdownCustomers(customerTemp);
      setEntryRows(temp);
      if (activeEntry?.[0]?.tags?.length > 0) {
        setSelectedTags(activeEntry?.[0]?.tags?.map((t) => t?.id));
      }
    }
  }, [activeEntry]);

  const updateTags = async (id, data) => {
    try {
      await updateJournalEntryTags(id, { tags: data });
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleSubmit = async (values) => {
    if (checkEntryRowsError()) {
      setIsLineItemsError(true);
      setTimeout(() => setIsLineItemsError(false), 3000);
      return;
    }
    let debits = 0;
    entryRows?.forEach((d) => {
      debits += parseFloat(d.debit.value || 0);
    });
    let credits = 0;
    entryRows?.forEach((d) => {
      credits += parseFloat(d.credit.value || 0);
    });

    if (debits - credits !== 0) {
      setIsLineItemsError(true);
      setTimeout(() => setIsLineItemsError(false), 3000);
      return;
    }
    let response;
    const { date, descriptionEntry, currency, notes, accountType } = values;
    const lines = [];
    entryRows?.forEach((row) => {
      const d = {
        accountId: row?.categoryAccountId?.value,
        descriptionLine: row?.description?.value,
      };
      if (row?.vendorId?.value) {
        d.vendorId = row?.vendorId?.value;
      }
      if (row?.customerId?.value) {
        d.customerId = row?.customerId?.value;
      }
      if (row?.projectId?.value) {
        d.projectId = row?.projectId?.value;
      }
      if (row?.productId?.value) {
        d.productId = row?.productId?.value;
      }
      if (row?.tags?.value?.length > 0) {
        d.tags = row?.tags?.value;
      }
      if (row?.debit?.value && !row?.credit?.value) {
        d.amountDebit = parseFloat(row?.debit?.value);
      }
      if (row?.credit?.value && !row?.debit?.value) {
        d.amountCredit = parseFloat(row?.credit?.value);
      }
      lines?.push(d);
    });
    try {
      setAddEditLoader(true);
      if (activeEntry?.[0]?.journalLinkUuid) {
        response = await updateJournalEntry(
          {
            currency,
            date: moment(date).format('YYYY-MM-DD'),
            descriptionEntry,
            notes,
            lines,
          },
          activeEntry?.[0]?.id,
        );
        if (response?.data?.results?.[0]?.id) {
          await updateTags(response?.data?.results?.[0]?.id, selectedTags);
        }
        await getData({});
      } else {
        response = await createJournalEntries({
          currency,
          date: moment(date).format('YYYY-MM-DD'),
          descriptionEntry,
          notes,
          lines,
        });
        if (selectedTags?.length > 0 && response?.data?.results?.[0]?.id) {
          await updateTags(response?.data?.results?.[0]?.id, selectedTags);
        }
        await getData({ page: 1 });
      }
      if (response) {
        formikRef.current?.resetForm();
        setFormOpen(false);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setAddEditLoader(false);
    }
  };

  const calcDebitTotal = () => {
    let sum = 0;
    entryRows?.forEach((d) => {
      sum += parseFloat(d.debit.value || 0);
    });
    return sum || 0;
  };

  const calcCreditTotal = () => {
    let sum = 0;
    entryRows?.forEach((d) => {
      sum += parseFloat(d.credit.value || 0);
    });
    return sum || 0;
  };

  const options = useMemo(
    () => ({
      mode: 'single',
      static: true,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat:
        team?.defaults?.dateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      maxDate: new Date(),
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }),
    [team],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        descriptionEntry: activeEntry?.[0]?.descriptionEntry || '',
        date:
          activeEntry?.[0]?.date ||
          new Date()?.toISOString?.()?.split?.('T')?.[0],
        currency: activeEntry?.[0]?.currency || selectedCurrency?.isoCode,
        notes: activeEntry?.[0]?.notes || '',
      }}
      validationSchema={Yup.object({
        descriptionEntry: Yup.string().required(
          'Please Enter Entry Description',
        ),
        date: Yup.string().required('Please Enter Date'),
        currency: Yup.string().required('Please Select Currency'),
        notes: Yup.string().nullable().optional(),
      })}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {(validation) => (
        <Form>
          <div className="grid grid-cols-12 gap-4 w-full mb-4">
            <div className="col-span-full md:col-span-6 xl:col-span-4 flex-col items-start gap-[1.875rem] w-full">
              <div className="gap-4 w-full relative">
                <TextInputDash
                  label="Entry Description"
                  id="descriptionEntry"
                  name="descriptionEntry"
                  placeholder="Entry Description"
                  required
                  value={validation.values.descriptionEntry || ''}
                  error={
                    !!(
                      validation.touched.descriptionEntry &&
                      validation.errors.descriptionEntry
                    )
                  }
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  paddingBottom="pb-2"
                />
              </div>
            </div>
            <div className="col-span-full md:col-span-6 xl:col-span-4  flex flex-col items-start gap-2.5">
              <DatePickerDash
                label="Date"
                required
                validation={validation}
                placeholder="Select Date"
                value={
                  validation.values.date
                    ? new Date(validation.values.date)
                    : null
                }
                options={options}
                onChange={(selectedDates) => {
                  validation.setFieldValue('date', selectedDates[0]);
                }}
                name="date"
                id="date"
                error={
                  validation.touched.date && validation.errors.date
                    ? validation.errors.date
                    : ''
                }
                width="w-full"
              />
            </div>
            <div className="col-span-full sm:col-span-full md:col-span-6 xl:col-span-4 ">
              <label
                className="block text-sm font-medium mb-2"
                htmlFor="currency"
              >
                Currency <span className="text-rose-500">*</span>
              </label>
              <DropdownFull
                options={currencies?.map((c) => ({
                  ...c,
                  id: c?.isoCode,
                  name: `${c?.symbol} ${c?.name}`,
                }))}
                name="name"
                setSelected={(id) => validation.setFieldValue('currency', id)}
                selected={validation.values.currency}
                error={
                  validation.touched.currency && validation.errors.currency
                }
                accountStyle
                className="!h-fit"
              />
            </div>

            <div className="col-span-full md:col-span-6 xl:col-span-4  flex flex-col items-start gap-2.5">
              <label
                className="block text-sm text-slate-600 font-medium leading-5"
                htmlFor="notes"
              >
                Note
              </label>
              <textarea
                name="notes"
                id="notes"
                placeholder="Write A Note"
                className={`form-textarea px-4 py-2.5 rounded-[0.313rem] text-slate-600 justify-between w-full font-normal leading-6 form-input border-[#D0D5DD] placeholder:text-slate-600 placeholder:text-opacity-80 placeholder:leading-6 placeholder:font-normal placeholder:text-base
                      ${
                        validation.touched.notes &&
                        validation.errors.notes &&
                        'border-rose-300'
                      }`}
                type="notes"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.notes || ''}
              />
              {validation.touched.notes && validation.errors.notes ? (
                <div className="text-xs mt-1 text-rose-500">
                  {validation.errors.notes}
                </div>
              ) : null}
            </div>
            <div className="col-span-full">
              <CreateJournalEntryTable
                allCategories={allAccounts}
                entryRows={entryRows}
                setEntryRows={setEntryRows}
                TABLE_ROW_SCHEMA={TABLE_ROW_SCHEMA}
                isLineItemsError={isLineItemsError}
                currency={currencies?.find?.(
                  (c) => c.isoCode === validation.values.currency,
                )}
                checkEntryRowsError={checkEntryRowsError}
                setIsLineItemsError={setIsLineItemsError}
                calcDebitTotal={calcDebitTotal}
                calcCreditTotal={calcCreditTotal}
                vendors={vendors}
                setVendors={setVendors}
                tags={tags}
                customers={customers}
                projects={projects}
                products={products}
                dropdownCustomers={dropdownCustomers}
                setDropdownCustomers={setDropdownCustomers}
                setCustomers={setCustomers}
              />
            </div>
            {/* Close/Add Buttons */}
          </div>
          <div className="flex justify-between flex-1 items-center">
            {activeEntry?.[0]?.id ? (
              <button
                className="h-[45px] px-[1rem]  rounded-lg bg-white border border-[#FF4B4B80] inline-flex justify-center items-center"
                onClick={onDeleteJournalEntry}
                type="button"
              >
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-1"
                >
                  <path
                    d="M14.5 4.48568C12.28 4.26568 10.0467 4.15234 7.82 4.15234C6.5 4.15234 5.18 4.21901 3.86 4.35234L2.5 4.48568"
                    stroke="#FF4B4B"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.16797 3.81203L6.31464 2.9387C6.4213 2.30536 6.5013 1.83203 7.62797 1.83203H9.37464C10.5013 1.83203 10.588 2.33203 10.688 2.94536L10.8346 3.81203"
                    stroke="#FF4B4B"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.0669 6.59375L12.6336 13.3071C12.5603 14.3537 12.5003 15.1671 10.6403 15.1671H6.36026C4.50026 15.1671 4.44026 14.3537 4.36693 13.3071L3.93359 6.59375"
                    stroke="#FF4B4B"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.38672 11.5H9.60672"
                    stroke="#FF4B4B"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Delete
              </button>
            ) : (
              <div />
            )}
            <div className="flex items-center">
              <button
                className="flex items-center mr-5 justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-white border border-slate-600 text-base w-[6.875rem] h-11 text-slate-600 leading-6"
                onClick={(event) => {
                  event.preventDefault();
                  formikRef.current?.resetForm();
                  // setActiveEntry(null);
                  setTitle('Journal Entries');

                  setFormOpen(false);
                }}
                type="button"
              >
                {activeEntry ? 'Discard' : 'Close'}
              </button>
              <button
                disabled={loading}
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-[6.875rem] h-11 text-white leading-6"
                type="submit"
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}

                {activeEntry ? 'Save' : 'Add'}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default JournalEntryForm;
